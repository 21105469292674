import React from 'react';

function DummyPage() {
  return (
    <div>
      <h2>Dummy Page</h2>
      <p>This is a dummy page for demonstration purposes.</p>
    </div>
  );
}

export default DummyPage;
