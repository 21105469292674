// src/components/FilmList.js
import React, { useEffect, useState } from 'react';
import moviesData from '../movies.json'; // Import the JSON data

function FilmList({ selectedGenre }) {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    setMovies(moviesData); // Set the movies data
  }, []);

  // Filter movies based on selected genre
  const filteredMovies = selectedGenre
    ? movies.filter(movie => movie.genre === selectedGenre)
    : movies; // Show all movies if no genre is selected

  // Group movies by genre
  const groupedMovies = filteredMovies.reduce((acc, movie) => {
    (acc[movie.genre] = acc[movie.genre] || []).push(movie);
    return acc;
  }, {});

  return (
    <div className="film-list">
      <h2>Available Movies</h2>
      {Object.keys(groupedMovies).length > 0 ? (
        Object.keys(groupedMovies).map(genre => (
          <div key={genre}>
            <h3>{genre}</h3>
            <ul>
              {groupedMovies[genre].map(movie => (
                <li key={movie.id}>
                  <h4>{movie.title}</h4>
                  <p>{movie.overview}</p>
                  <p><strong>Release Date:</strong> {movie.release_date}</p>
                  <p><strong>Rating:</strong> {movie.vote_average}</p>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>No movies found for this genre.</p>
      )}
    </div>
  );
}

export default FilmList;
