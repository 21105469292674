import React from 'react';
import { useParams } from 'react-router-dom';

function FilmPage() {
  const { id } = useParams();
  return (
    <div className="film-page">
      <h2>Film Details for Film ID: {id}</h2>
      <p>Here you can display the film's details based on the ID.</p>
    </div>
  );
}

export default FilmPage;
