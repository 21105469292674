import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <nav className="navbar">
      <div className="navbar-brand">Jio Star Cinema</div>
      <ul className="navbar-links">
        <li><a href="/">Home</a></li>
        <li onClick={toggleDropdown} className="dropdown">
          Services
          {dropdownOpen && (
            <ul className="dropdown-menu">
              <li><a href="/dummy">Dummy Page</a></li>
              <li><a href="/">Another Service</a></li>
            </ul>
          )}
        </li>
        <li><a href="/submit">Submit Analysis</a></li>
        <li><button className="nav-button">Login</button></li>
        <li><button className="nav-button signup-button">Sign Up</button></li>
      </ul>
    </nav>
  );
}

export default Navbar;
