// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import FilmList from './components/FilmList';
import FilmPage from './components/FilmPage';
import SubmitAnalysis from './components/SubmitAnalysis';
import Footer from './components/Footer';
import DummyPage from './components/DummyPage';
import './App.css';

function App() {
  const [selectedGenre, setSelectedGenre] = useState(""); // Initialize with an empty string

  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <div className="main-content">
          <Sidebar onSelectGenre={setSelectedGenre} />
          <main>
            <Routes>
              <Route path="/" element={<FilmList selectedGenre={selectedGenre} />} />
              <Route path="/film/:id" element={<FilmPage />} />
              <Route path="/submit" element={<SubmitAnalysis />} />
              <Route path="/dummy" element={<DummyPage />} />
            </Routes>
          </main>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
