import React from 'react';

function SubmitAnalysis() {
  return (
    <div className="submit-analysis">
      <h2>Submit Film Analysis</h2>
      <form>
        <textarea placeholder="Write your analysis here..." rows="5" cols="50"></textarea>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default SubmitAnalysis;
